/* App.css */
/* .rtl-wrapper {
  direction: rtl !important;
} */

.App {
  text-align: center;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 1;
  overflow: auto;
  padding-bottom: 1rem;
}

.App-header {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.about-img {
  width: 80%;
  /* Reduce the width for desktop view */
}

.brand {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  /* Adjust the size of the brand text */
  font-weight: bold;
  color: #f8f9fa;
  /* Set the color for the brand text */
}

.brand-icon {
  font-size: 1.8rem;
  /* Adjust the size of the icon */
  color: #00c851;
  /* Choose a color that matches your brand, such as a green tone */
}

.brand-text {
  color: inherit;
  /* Make sure the brand text inherits the correct color */
}


.cursor-pointer {
  cursor: pointer !important;
}

.testimonial-bg {
  background-color: #f8f9fa;
  /* Set your desired background color */
  padding: 15px;
  border-radius: 5px;
}

footer {
  background-color: #282c34;
  color: white;
  padding: 1rem;
  position: relative;
  /* or 'fixed' */
  bottom: 0;
  width: 100%;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wiggle {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }
}

.animate-call-to-action {
  animation: wiggle 0.5s ease-in-out;
}


@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin);
    padding: 1rem;
  }

  .App {
    padding: 1rem;
  }

  .about-img {
    width: 100%;
    /* Full width for mobile view */
  }

  footer {
    padding: 0.5rem;
  }
}